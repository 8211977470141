.ProseMirror {
  outline: none;
}

.ProseMirror.readonly {
  /* border-top: 1px solid #e2e8f0; */
  font-family: 'Inter';
}

.ProseMirror .image {
  display: block;
  margin: auto;
  height: auto;
  max-height: 600px;
  max-width: 100%;
}

.ProseMirror-selectednode {
  outline: 3px solid #68cef8;
}

.ProseMirror.readonly p {
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 1rem;
  color: #2d3748;
}
.ProseMirror.readonly h1 {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5;
  color: #2d3748;
}
.ProseMirror.readonly h2 {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5;
  color: #2d3748;
}
.ProseMirror.readonly h3 {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5;
  color: #2d3748;
}
.ProseMirror.readonly h4 {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5;
  color: #2d3748;
}
.ProseMirror.readonly h5 {
  font-weight: 700;
  font-size: 13px;
  line-height: 1.5;
  color: #2d3748;
}
